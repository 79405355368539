$black: #131313;
$blue: #53B9EA;
$gray: #B9B9C2;
$white: #FFFFFF;
$ltBlue:#40DDFF;
$ltPurple: #5B5A99;
$headlineColor: #B1AFCD;

$blueGradient: linear-gradient(135deg, #40DDFF 0%, #14BAE3 19.24%, #13B1E6 68.64%, #11AADF 81.77%, #0B98C5 100%);
$redGradient: linear-gradient(135deg, #FF4040 0%, #E31414 19.24%, #E61313 68.64%, #DF1111 81.77%, #C50B0B 100%);

$menuBackground: linear-gradient(180deg, rgba(38, 34, 80, 0.65) 0%, rgba(27, 25, 66, 0.85) 57.44%, #17163B 100%);
$contentBackground: #222239;

$cookieGradient: linear-gradient(268.95deg, rgba(41, 37, 88, 0.74) 4.06%, rgba(38, 35, 83, 0.866791) 42.35%, rgba(40, 38, 89, 0.87) 99.53%);

$mobileBreakpoint: 1024px;

@import url('https://fonts.googleapis.com/css2?family=Karla:wght@300;500;800');

body {
    background: $black;
    color: $white;
    font-family: 'Karla', sans-serif;
    margin: 0;    

    a {
        color: $white;
    }

    .tooltip {
        font-size: 13px;
    }

    h1 {
        color: $white;
        font-family: 'Karla', sans-serif;
        font-weight: 500;
        margin: 0;
    }

    h2 {
        color: $headlineColor;
        font-weight: 300;
        font-size: 18px;
        text-transform: uppercase;
    }

    h3 {
        color: $headlineColor;
        font-weight: 300;
        font-size: 15px;
        text-transform: uppercase;
    }

    .button {
        background: transparent;
        border: 1px solid $gray;
        border-radius: 9px;
        box-sizing: border-box;
        display: inline-flex;
        font-weight: 300;
        justify-content:center;
        color: $white;
        cursor: pointer;     
        margin-right: 10px;
        margin-bottom: 10px;
        text-align: center;        
        width: 175px;
        height: 65px; 

        > div {
            margin: auto;
        }
    }

    .button:hover {
        border: 1px solid $ltBlue;
    }

    .buttonActive {
        background: $blueGradient;
        border: none;
        font-weight: 800;
    }

    .buttonActive:hover, .buttonDanger:hover {
        border: none;
    }

    .buttonDanger {
        background: $redGradient;
        border: none;
    }

    .buttonSmall {
        padding: 0 25px;
        height: 50px;   
        width: 120px;
    }

    textarea {
        background: $white;
        border: none;
        border-radius: 9px;
        box-sizing: border-box;
        min-height: 300px;
        overflow: hidden;
        padding: 20px;
        resize: none;
        width: 100%;
        max-width: 700px;
    }

    

    hr {
        color: $white;
        margin: 35px 0;        
    }

    .listComponent {
        display: inline-flex;

        .listItem {            
            border: 1px solid $gray;
            border-radius: 9px;
            box-sizing: border-box;
            display: inline-flex;
            margin-right: 10px;
            margin-bottom: 10px;   
            position: relative;
            //width: 101px;
            padding: 0 35px;
            height: 50px;    
            
            > div {
                margin: auto;
            }

            img {
                cursor: pointer;
                position: absolute;
                right: 7px;
                top: 7px;
            }
        }

        .button {
            font-weight: 500;
            padding: 0 25px;
            height: 50px;   
            width: 120px;
        }

        .addItemWrapper {
            display: inline-flex;
            margin-right: 10px;

            > div {
                display: inline-block;
                vertical-align: top;
            }

            input {
                background: transparent;
                border: 1px dashed #B9B9C2;
                border-radius: 9px;
                box-sizing: border-box;
                color: white;
                height: 50px; 
                text-align: center;
                width: 130px;
            }

            :focus {
                border: 1px solid $ltBlue;
                outline: none;
            }

            .addButton {
                background: $gray;
                box-sizing: border-box;
                cursor: pointer;
                color: $black;
                height: 35px;
                padding: 5px 25px;
            }

            .addButton:hover {
                background: $blueGradient;
            }
        }
    }
    
}

.contentWrapper {
    display: grid;
    grid-template-areas: 
        "left right ad";
    grid-template-columns: 300px auto 200px;

    @media(max-width: $mobileBreakpoint) {
        grid-template-areas: 
            "right";
        grid-template-columns: auto;
    }
}

.col {
    box-sizing: border-box;  
    display: grid;

    h2 {
        line-height: 1;
        margin-top: 0;
    }

    
    .optionWrapper {
        margin: 50px 0;
    }

}

.leftCol {
    background: $menuBackground;
    grid-area: left;
    justify-content: center;
    align-content: start;

    @media(max-width: $mobileBreakpoint) {
        display: none;
    }

    .logo {
        margin-bottom: 80px;
        margin-top: 20px;
        text-align: center;
    }   
    

    .menuItem {
        height: 36px;
        margin-bottom: 50px;

        > div {
            display: inline-block;
            vertical-align: middle;
        }

        .menuIcon {    
            height: 25px;
            width: 25px;
            margin-left: 25px;
            position: relative;
            top: 4px;
                        
            img {
                height: 25px;
                width: 25px;                
            }
        }

        .menuText {
            margin-left: 10px;
            position: relative;
            text-transform: capitalize;
            top: 4px;            
        }

        .activeIcon {
            position: absolute;
            left: 0;
        }
    }

    .menuItemActive {
        .menuText {
            font-weight: 800;
            color: $blue;        
        }
    }
}

.rightCol {
    background: $contentBackground;
    grid-area: right; 
    padding: 30px 50px ;

    @media(min-width: $mobileBreakpoint) {
        .mobileLogo {
            display: none;
        }
    }    

    .subtitle {
        color: $blue;
        font-size: 13px;;
    }
}

.adCol {
    background: $contentBackground;
    grid-area: ad; 

    @media(max-width: $mobileBreakpoint) {
        display: none;
    }
}

.cookieAlert {
    background: $cookieGradient;
    box-sizing: border-box;
    border-radius: 11px;
    box-shadow: -30.8563px 42.0768px 84.1535px #17122B;
    padding: 35px 65px;
    position: fixed;
    left: 50px;
    bottom: 50px;
    right: 50px;
    max-width: 1300px;
    z-index: 10;

    .cookieIcon {
        position: absolute;
        left: 15px;
        top: 32px
    }

    .cookieHeadline {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 20px;
    }

    .cookieSubheadline {
        font-weight: bold;
        font-size: 16px;
    }

    .cookieContent {
        margin-top: 20px;
        > div {
            display: inline-block;
            vertical-align: middle;
        }

        .cookieText {
            color: $gray;
            font-size: 15px;
            width: 66%;
        }

        .cookieButtons {
            width: 34%;
        }
    }
}